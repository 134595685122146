<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="promotionDetails?.products"
      :loading="false"
      :items-per-page="10"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      show-expand
      :expanded.sync="expanded"
      :single-expand="singleExpand"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.product.images && item.product.images[0]?.url"
          :src="item.product.images[0]?.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.stock="{ item }">
        <span>
          {{ item.lots.reduce((sum, lot) => sum + lot.qte_in_lot, 0) }}
        </span>
      </template>
      <template v-slot:item.discount_price="{ item }">
        <span>
          {{
            (() => {
              let discountPrice =
                (item.product.price * promotion.discount) / 100;
              if (discountPrice >= parseFloat(promotion.max_discount_amount)) {
                return Number(promotion.max_discount_amount).toFixed(2);
              }
              return Number(discountPrice).toFixed(2);
            })()
          }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('promotion-view')"
          small
          class="mr-2"
          @click="editProduct(item.product)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('promotion-view')"
          small
          class="mr-2"
          @click="editProduct(item.product)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length"
          class="py-2 deep-purple lighten-5"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Batch N°</th>
                  <th class="text-left">{{ $t("quantity") }}</th>
                  <th class="text-left">Expiration Date</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="lot in item.lots"
                  :key="lot.id"
                >
                  <td>{{ lot.lot_number }}</td>
                  <td>{{ lot.lot_number }}</td>
                  <td>{{ lot.expiration_date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
    </v-data-table>
    <div class="mt-8 d-flex">
      <v-btn
        class="ml-auto primary"
        @click="createPromotion()"
      >
        <v-icon>mdi-content-save</v-icon>&nbsp;&nbsp;{{
          this.$t("promotions.submit")
        }}
      </v-btn>
    </div>
    <add-product-modal
      v-if="addedProduct"
      :isAddProductModalVisible="isAddProductModalVisible"
      :toggleAddProductModal="toggleAddProductModal"
      :product="addedProduct"
      :promotion="promotion"
    ></add-product-modal>
    <create-promotion-modal
      v-if="isCreatePromotionModalVisible"
      :promotion="promotion"
      :promotionDetails="promotionDetails"
      :toggleCreatePromotionModal="toggleCreatePromotionModal"
      :isCreatePromotionModalVisible="isCreatePromotionModalVisible"
    ></create-promotion-modal>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import service from "@/store/services/promotions-service";

export default {
  components: {
    AddProductModal: () => import("../modals/AddProductModal.vue"),
    CreatePromotionModal: () => import("../modals/CreatePromotionModal.vue"),
  },
  computed: {
    ...mapGetters({
      hubs: "hubs/activeHubs",
      promotionDetails: "promotions/promotionDetails",
      promotion: "promotions/promotion",
      productsList: "promotions/productsList",
    }),
  },
  data() {
    return {
      is_loading_hubs: false,
      options: {},
      expanded: [],
      index: 0,
      is_loading: false,
      is_loading_invoice: false,
      is_loading_dn: false,
      singleExpand: false,
      addedProduct: null,
      isAddProductModalVisible: false,
      isCreatePromotionModalVisible: false,

      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "",
          align: "start",
          sortable: true,
          value: "image",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "product.name",
        },

        {
          text: this.$t("stock"),
          align: "start",
          sortable: false,
          value: "stock",
        },
        {
          text: this.$t("price"),
          align: "start",
          sortable: false,
          value: "product.price",
        },

        {
          text: this.$t("discount_price"),
          value: "discount_price",
          align: "start",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    toggleAddProductModal() {
      if (this.isAddProductModalVisible) {
        this.addedProduct = null;
      }
      this.isAddProductModalVisible = !this.isAddProductModalVisible;
    },
    async editProduct(item) {
      let product = this.productsList.find((product) => product.id === item.id);
      if (!product) {
        product = await service
          .getProduct(this.promotion.id, item.id)
          .then((res) => res.product);
      }
      this.addedProduct = product;
      this.toggleAddProductModal();
    },
    toggleCreatePromotionModal() {
      this.isCreatePromotionModalVisible = !this.isCreatePromotionModalVisible;
    },
    createPromotion() {
      if (this.promotionDetails?.products.length > 0) {
        this.toggleCreatePromotionModal();
      } else {
        this.$swal({
          title: "",
          html: "Add items from <b>PRODUCTS LIST</b> to promotion first",
          icon: "info",
        });
      }
    },
  },
};
</script>
